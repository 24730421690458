import axios from 'axios';
import {
  get as _get, has as _has, isEmpty as _isEmpty, isNull as _isNull,
} from 'lodash';

import store from '../../app/store';

class AppService {
  constructor() {
    this.http = axios.create({
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_API_MAIN_URL,
    });
    this.http.defaults.params = {};
    this.initInterceptors();
  }

  initInterceptors() {
    this.http.interceptors.request.use(
      (request) => {
        const accessToken = this.getAccessToken();
        if (!_isNull(accessToken)) {
          const token = 'Bearer ' + accessToken
          request.headers.common['Authorization'] = token;
        }
        return request;
      },
      // eslint-disable-next-line promise/no-promise-in-callback
      (error) => Promise.reject(error)
    );

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response.status === 401 &&
          _has(error.response, 'config.url') &&
          _get(error.response, 'config.url') !== undefined &&
          _get(error.response, 'config.url') !== '/'
        ) {
          window.location = '/login';
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  init(accessToken = '') {
    this.setAccessToken(accessToken);
  }

  setAccessToken(accessToken) {
    if (!_isEmpty(accessToken)) {
      this.http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    } else {
      delete this.http.defaults.headers.common['Authorization'];
    }
  }
  
  getAccessToken() {
    const state = store.getState();
    const token = state?.user?.user?.token ?? null;
    this.init(token);
    return token;
  }

  reset() {
    delete this.http.defaults.headers.common['Authorization'];
    this.http.defaults.params = {};
  }

  getOptions() {
    return this.http.defaults.headers;
  }

  getPath(service) {
    return '/' + service;
  }

  logout() {
    return this.http.post('/auth/logout');
  }
}

export default AppService;
